<template>
  <v-parallax
    dark
    src="https://image.freepik.com/free-vector/bright-background-with-dots_1055-3132.jpg"
    height="600"
  >  
    <v-layout>
      <v-dialog v-model="dialog" persistent max-width="400px">
        <v-card color="rgba( 255,255,255 , 0.7 )">
          <v-card-title>
            <span class="headline">Select A Reservation</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm12
                  md12
                  d-flex
                >
                  <v-select
                    v-model="selectedReservation"
                    :items="reservations"
                    item-text="selectText"
                    item-value="selectedReservation"
                    return-object
                    v-on:change="chooseReservation"
                    label="Reservations"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-parallax>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    dialog: false,
    selectedReservation: null,
  }),
  created () {
    if (!this.reservation) {
      if (this.reservations.length === 0) {
        return this.logout()
      } else {
        this.dialog = true
        return
      }
    }
  },
  methods: {
    async chooseReservation(selectedReservation) {
      console.log('selectedReservation', selectedReservation)

      await this.pickReservation(selectedReservation)

      this.dialog = false

      if (this.allIdVerified) {
        this.$router.push({path: '/guide'})
      } else {
        this.$router.push({path: '/'})
      }

    },
    ...mapActions({
      // loadReservations: 'checkIn/loadReservations',
      pickReservation: 'checkIn/pickReservation',
      logout: 'session/logout',
    })
  },
  computed: {
    ...mapState({
      reservations: state => state.checkIn.reservations,
      reservation: state => state.checkIn.reservation,
    }),
    ...mapGetters('checkIn', {
      allIdVerified: 'allIdVerified',
    })
  },
}
</script>